import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VToolbar,{attrs:{"flat":""}},[_c(VTextField,{attrs:{"append-icon":"mdi-magnify","hint":"Search (name or full id number)","persistent-hint":"","single-line":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VSpacer),_c(VSelect,{attrs:{"multiple":"","items":Object.values(_vm.diets||{}),"hint":"Filter Diet/Stream","single-line":"","persistent-hint":"","item-value":"name","item-text":"stream","clearable":""},model:{value:(_vm.filterDiets),callback:function ($$v) {_vm.filterDiets=$$v},expression:"filterDiets"}}),_c(VSpacer),_c(VSelect,{attrs:{"multiple":"","items":'packaged,plated,closeup'.split(','),"hint":"Filter Missing","persistent-hint":"","single-line":"","clearable":""},model:{value:(_vm.filterMissingTypes),callback:function ($$v) {_vm.filterMissingTypes=$$v},expression:"filterMissingTypes"}})],1),_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.filteredMeals,"sort-by":"id","sort-desc":true,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"mr-2",class:{ yellow : item.is_selected},attrs:{"icon":"","shaped":"","disabled":""}},[_c(VIcon,[_vm._v("mdi-star")])],1),_c('MealSkuLink',{attrs:{"sku":item.sku}})]}},{key:"item.images",fn:function(ref){
var item = ref.item;
return [_c(VRow,[(item.noImages)?_c(VCol,[_c('router-link',{attrs:{"to":{name:'MealImages', params: { id: item.id}}}},[_vm._v("Add Images")])],1):_vm._e(),_vm._l((item.images),function(i,key){return _c(VCol,{key:i.url},[_c('router-link',{attrs:{"to":{name:'MealImages', params: { id: item.id}}}},[_vm._v(" "+_vm._s(key)+" "),_c(VImg,{attrs:{"max-height":"50","max-width":"50","src":i.url,"alt":key}})],1)],1)})],2)]}},{key:"item.sku_images",fn:function(ref){
var item = ref.item;
return [(item.sku_images)?_c(VRow,_vm._l((item.sku_images),function(i,key){return _c(VCol,{key:i.url},[_c('router-link',{attrs:{"to":{name:'MealImages', params: { id: item.id}}}},[_vm._v(" "+_vm._s(key)+" "),_c(VImg,{attrs:{"max-height":"50","max-width":"50","src":i.url,"alt":key}})],1)],1)}),1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }